@use 'src/styles/variables' as *;

.toast-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: $spacing-sm $spacing-none $spacing-md;
  text-align: center;

  &__icon svg {
    width: $icon-size-largest;
    height: $icon-size-largest;
  }

  &__title {
    margin-bottom: $spacing-2xs;
    margin-top: $spacing-2xs;
    font-weight: $font-weight-bold;
    font-size: $font-size-xs;
    line-height: $line-height-xs;
  }

  &__description {
    max-width: 590px;
    font-size: $font-size-2xs;
    font-weight: $font-weight-medium;
    color: $secondary-text;
  }

  @media (max-width: 768px) {
    &__icon svg {
      width: $icon-size-large;
      height: $icon-size-large;
    }

    &__title {
      margin-bottom: $spacing-2xs;
      margin-top: $spacing-3xs;
      font-size: $font-size-2xs;
      line-height: $line-height-2xs;
    }

    &__description {
      font-size: $font-size-3xs;
      line-height: $line-height-3xs;
    }
  }
}
