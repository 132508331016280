@use 'src/styles/variables' as *;

.reservation-list {
  padding: $spacing-2xl;
  @media (min-width: 769px) {
    min-height: calc(100vh - 157px);
  }

  &__reservations-count {
    font-family: $font-family-mono;
    margin-bottom: $spacing-sm;
    text-transform: uppercase;
  }

  &__filters {
    display: flex;

    @media (max-width: 590px) {
      flex-direction: column;
    }
  }

  .pagination {
    a[type='ellipsisItem'] {
      display: none !important;
    }
  }

  @media (max-width: 768px) {
    padding: $spacing-lg;

    &__reservations-count {
      font-size: $font-size-3xs;
      margin-bottom: $spacing-xs;
    }
  }
}
