@use 'src/styles/variables' as *;

.reservation-line {
  display: flex;
  justify-content: space-between;
  margin-bottom: $spacing-2xs;
  &__status {
    width: 20%;
    text-align: center;
    padding: $spacing-4xs $spacing-2xs;
    background-color: $color-red-medium;
  }
  &__status_checked_in {
    background-color: $color-mint-light;
  }
  &__label {
    color: $color-mint-dark;
  }
  &__name {
    width: 35%;
    text-align: center;
  }
  &__date {
    width: 25%;
    text-align: center;
  }
  &__info {
    width: 20%;
    text-align: center;
  }
}
