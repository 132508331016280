@use 'src/styles/variables' as *;

$black-transparent: rgba(0, 0, 0, 0.1);

.spinner-overlay {
  height: 100%;
  width: 100%;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 1000;
    background-color: rgba(255, 255, 255, 0.8);
  }
  &:after {
    position: absolute;
    content: '';
    top: 50%;
    left: 50%;
    margin: -1.5em 0 0 -1.5em;
    width: 3em;
    height: 3em;
    animation: segment-spin 0.6s linear;
    animation-iteration-count: infinite;
    border-radius: 500rem;
    border-color: #2b2b33 $black-transparent $black-transparent $black-transparent;
    border-style: solid;
    border-width: 0.2em;
    box-shadow: 0 0 0 1px transparent;
    visibility: visible;
    z-index: 101;
  }
}

.theme-dark {
  .spinner-overlay {
    &:before {
      background-color: rgba(84, 94, 119, 0.8);
    }
  }
}
