@use 'src/styles/variables' as *;

.reservation-item {
  display: flex;
  margin-bottom: $spacing-sm;
  justify-content: space-between;
  align-items: center;
  border: 1px solid $default-border;
  border-radius: $border-radius-md;
  font-size: $font-size-2xs;
  font-weight: $font-weight-medium;
  line-height: $line-height-xs;
  background-color: $color-gray-light;
  transition: 250ms;
  cursor: pointer;

  &__wrapper {
    padding: $spacing-sm;
  }

  &__custom-checkbox {
    display: flex;
    align-items: center;
    transform: scale(1.5);
  }

  &__info {
    display: flex;
    flex: 1;
    align-items: baseline;
    justify-content: space-between;
    padding: $spacing-sm;
  }

  &:hover {
    border-color: $active-border;
    box-shadow: $box-shadow-tight-topless;
  }

  &_loading {
    .ui.placeholder {
      width: 100%;

      .image {
        width: $icon-size-large;
        height: $icon-size-large;
      }
    }
  }

  @media (max-width: 768px) {
    font-size: $font-size-3xs;
    margin-bottom: $spacing-xs;

    &__wrapper {
      padding: $spacing-xs;
    }

    &__info {
      padding: $spacing-xs;
    }
  }
}
