@use 'src/styles/variables' as *;

.search-filter {
  position: relative;
  margin-bottom: $spacing-lg;

  &__input {
    padding: $spacing-xs $spacing-lg $spacing-xs $spacing-xl;
    border: 1px solid $default-input-border;
    border-radius: $border-radius-sm;
    font-size: $font-size-2xs;
    font-family: $font-family-mono;
    line-height: $line-height-3xs;
    outline: 0;
    color: $color-gray-dark;
    margin-right: $spacing-sm;
    &:focus {
      border-color: $active-input-border;
      box-shadow: $box-shadow-regular;
    }
  }

  &__icon {
    width: $icon-size-medium;
    height: $icon-size-medium;
    position: absolute;
    top: $spacing-sm;
    left: $spacing-sm;
    color: $color-gray-medium;
    pointer-events: none;
  }

  @media (max-width: 768px) {
    margin-bottom: $spacing-sm;

    &__input {
      padding: $spacing-2xs $spacing-md $spacing-2xs $spacing-lg;
      font-size: $font-size-3xs;
    }

    &__icon {
      top: $spacing-xs;
      left: $spacing-2xs;
    }
  }
}
