@use 'src/styles/variables' as *;

.header {
  display: flex;
  padding: $spacing-md $spacing-2xl;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $color-gray-light;

  &__logo {
    height: $icon-size-large;
  }

  @media (max-width: 768px) {
    padding: $spacing-sm $spacing-lg;
    flex-direction: column;
    align-items: flex-start;
  }
}
