@use 'src/styles/variables' as *;

.logout-button {
  width: 100px;
  padding: $spacing-2xs;
  align-items: center;
  justify-content: center;
  border: 1px solid $default-input-border;
  border-radius: $border-radius-md;
  background-color: $color-gray-light;
  font-size: $font-size-4xs;
  line-height: $line-height-4xs;
  font-weight: $font-weight-bold;
  text-transform: uppercase;
  cursor: pointer;
  background-color: $default-background-color;
  color: $color-medium-blue;
  border: 1px solid $color-medium-blue;
  text-shadow: none;
  background-image: none;

  &:hover {
    background-color: $active-border;
  }
}
