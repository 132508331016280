@use 'src/styles/variables' as *;

// Global style rules can go here.

* {
  box-sizing: border-box;
}

body,
html {
  margin: $spacing-none;
  padding: $spacing-none;

  font-family: $font-family-main;
  font-weight: $font-weight-normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;

  color: $default-primary-text;
  font-size: 16px;
  line-height: 1.618;
}

body {
  background-color: $default-background-color;
  background-repeat: no-repeat;
}
