// Miscellaneous variables used by the application

$color-gray-dark: #2a303c;
$color-gray-medium: #535f78;
$color-gray-light: #f6f7f9;

$color-mint-dark: #0a5c47;
$color-mint-medium: #47ebc1;
$color-mint-light: #e8fdf7;

$color-red-dark: #ff6666;
$color-red-medium: #ff9999;
$color-red-light: #ffcccc;

$color-medium-blue: #00f;

$default-primary-text: #000000;
$secondary-text: #545e77;

$default-input-border: #b0b0b0;
$active-input-border: #9999ff;

$default-border: #e1e4ea;
$active-border: #ccccff;

$default-background-color: #ffffff;

$border-thin: 1px;

$box-shadow-regular: 0px 4px 16px rgba(0, 0, 0, 0.1);
$box-shadow-tight: 0px 8px 4px 1px rgba(0, 0, 0, 0.15);
$box-shadow-tight-topless: 0px 4px 4px 1px rgba(0, 0, 0, 0.15);

$icon-size-small: 12px;
$icon-size-medium: 16px;
$icon-size-large: 24px;
$icon-size-largest: 32px;

$opacity-low: 0.1;
$opacity-medium: 0.4;
$opacity-high: 0.6;

$spacing-none: 0;
$spacing-4xs: 2px;
$spacing-3xs: 4px;
$spacing-2xs: 8px;
$spacing-xs: 12px;
$spacing-sm: 16px;
$spacing-md: 24px;
$spacing-lg: 32px;
$spacing-xl: 48px;
$spacing-2xl: 64px;
$spacing-3xl: 72px;
$spacing-4xl: 128px;

$font-family-main:
  Apercu,
  Avenir,
  -apple-system,
  BlinkMacSystemFont,
  Helvetica Neue,
  Helvetica,
  Calibri,
  Roboto,
  Arial,
  sans-serif;
$font-family-mono:
  Apercu Mono,
  SFMono-Regular,
  Menlo,
  Monaco,
  Consolas,
  Liberation Mono,
  Courier New,
  monospace;

// 1 rem = 16 px
$font-size-5xl: 4rem;
$font-size-4xl: 3.5rem;
$font-size-3xl: 3rem;
$font-size-2xl: 2.5rem;
$font-size-xl: 2.25rem;
$font-size-lg: 2rem;
$font-size-md: 1.75rem;
$font-size-sm: 1.5rem;
$font-size-xs: 1.25rem;
$font-size-2xs: 1rem;
$font-size-3xs: 0.875rem;
$font-size-4xs: 0.75rem;
$font-size-5xs: 0.625rem;

$line-height-5xl: 5rem;
$line-height-4xl: 4.5rem;
$line-height-3xl: 4rem;
$line-height-2xl: 3.5rem;
$line-height-xl: 3rem;
$line-height-lg: 2.5rem;
$line-height-md: 2.25rem;
$line-height-sm: 2rem;
$line-height-xs: 1.75rem;
$line-height-2xs: 1.5rem;
$line-height-3xs: 1.25rem;
$line-height-4xs: 1rem;
$line-height-5xs: 0.875rem;

$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 600;

$border-radius-none: 0px;
$border-radius-sm: 4px;
$border-radius-md: 6px;
$border-radius-lg: 8px;
$border-radius-xl: 16px;
$border-radius-full: 9999px;
