@use 'src/styles/variables' as *;

.footer {
  width: 100%;
  height: 100%;
  margin: $spacing-none;
  padding: $spacing-md $spacing-none;
  text-align: center;
  font-size: $font-size-2xs;
  font-weight: $font-weight-normal;
  color: $color-gray-medium;

  @media (max-width: 768px) {
    font-size: $font-size-3xs;
    padding: $spacing-sm $spacing-none;
  }
}
