@use 'src/styles/variables' as *;

.profile {
  display: flex;
  align-items: center;
  justify-content: center;

  &__avatar {
    margin-right: $spacing-3xs;
    border-radius: 50%;
    border: 1px solid $color-gray-light;
    background-color: $color-gray-light;
  }

  &__user-name {
    font-size: $font-size-2xs;
    font-weight: $font-weight-bold;
    margin-right: $spacing-xs;
  }

  @media (max-width: 768px) {
    margin-top: $spacing-xs;
    &__user-name {
      font-size: $font-size-3xs;
    }
  }
}
