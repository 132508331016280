@use 'src/styles/variables' as *;

.date-selector {
  &__input,
  &__button {
    height: 46px;
    border: 1px solid $default-input-border;
    font-size: $font-size-2xs;
    padding: 0.4em 0.6em;
  }

  &__input {
    border-radius: $border-radius-sm 0 0 $border-radius-sm;
    border-right-width: 0;
  }
  &__button {
    border-radius: 0 $border-radius-sm $border-radius-sm 0;
    position: relative;
    bottom: 0.5px;
    cursor: pointer;
  }

  @media (max-width: 768px) {
    margin-bottom: $spacing-md;

    &__input,
    &__button {
      height: 38px;
      font-size: $font-size-3xs;
    }
  }
}

.rdp-months {
  padding: $spacing-2xs;
  button {
    font-size: $font-size-2xs;
    padding: 0.4em 0.6em;
  }
}

.rdp-caption {
  &_label {
    position: absolute;
    width: 140px;
    margin-left: calc(50% - 70px);
    text-align: center;
  }
}

.rdp-nav {
  display: flex;
  justify-content: space-between;
  margin: 0 $spacing-4xs;
  button {
    padding: 0.4em 0.6em 0.2em 0.6em;
  }
}

.rdp-day {
  width: 100%;
}

.dialog-sheet {
  background: $default-background-color;
  border-radius: $border-radius-sm;
  box-shadow: $box-shadow-regular;
  top: $spacing-xs !important;
}

.rdp-table {
  padding-top: $spacing-2xs;
  tbody:before {
    content: '0';
    display: block;
    line-height: $spacing-3xs;
    text-indent: -99999px;
  }
}
